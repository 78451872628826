<template>
  <v-card>
    <v-card-title class="headline justify-center" :key="refreshKey">
      Memberships
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row dense>
        <v-col cols="12" sm="6" md="4" lg="3" v-for="(body, i) in bodies" :key="i">
          <v-text-field
            :readonly="body.validate"
            :label="body.label"
            v-model="model[body.model]"
            :hint="model[body.model] ? '' : body.hint"
            persistent-hint
            color="color3"
            @focus="onFocus(body)"
            :disabled="!editing"
            :class="{warn: editing && model[body.model] && body.expired}"
            :error-messages="editing && model[body.model] && body.expired ? [`Your ${body.label} is currently expired`] : []"
          >
            <v-icon slot="prepend-inner" v-if="model[body.model] && body.expired" :color="editing ? 'error' : null">fas fa-exclamation-triangle</v-icon>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="user && user.vbl">
        <membership-add :player="player"></membership-add>
      </v-row>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      scrollable
      persistent
      max-width="500px"
      transition="dialog-transition"
    >
      <v-expand-transition>
        <v-card v-if="selectedBody">
          <v-toolbar color="white" class="pl-0">
            <v-img
              height="40px"
              style="max-width: 95px"
              contain
              :src="selectedBody.logo.url"
            ></v-img>
            <v-spacer></v-spacer>
            <v-btn
              color="error white--text"
              small fab
              @click.stop="dialog = false"
            >
              <v-icon>fas fa-times</v-icon>
            </v-btn>
          </v-toolbar>
          <loading-bar :value="validating || loading"></loading-bar>
          <v-card-title class="headline">
            {{model[selectedBody.model] ? 'Update' : 'Add'}} your {{selectedBody.label}}
          </v-card-title>

          <v-card-text>
            <v-window v-model="window" touchless>
              <v-window-item :value="0">
                <v-row dense>
                  <v-col cols="12" class="subheading" v-if="selectedBody.request">
                    If your {{selectedBody.label}} is incorrect or missing, please send a request to <a class="color3--text" href="mailto:NumberCheck@VolleyballLife.com">NumberCheck@VolleyballLife.com</a>
                  </v-col>
                  <v-col cols="12" class="subheading mt-4 text-center" v-if="selectedBody.request && selectedBody.model.includes('p1440')">
                    <div>Don't have a {{selectedBody.label}}?</div>
                    <v-btn color="color3 color3Text--text" :to="'/member/levels'" v-if="subdomain === 'p1440'">Get a {{selectedBody.label}}</v-btn>
                    <v-btn color="color3 color3Text--text" v-else href="https://play.p1440.com/member/levels" target="_blank">Get a {{selectedBody.label}}</v-btn>
                  </v-col>
                  <v-col cols="12" v-else class="text-center">
                    <v-text-field
                      outline
                      :label="selectedBody.label"
                      v-model="numToValidate"
                      :hint="`Enter your ${selectedBody.label} and click validate`"
                      persistent-hint
                      color="color3"
                      :error-messages="numToValidate && numToValidate === model[selectedBody.model] ? ['This is the number we currently have on file'] : []"
                    >
                    </v-text-field>
                    <v-expand-transition>
                      <div v-if="numToValidate && numToValidate === model[selectedBody.model]">
                        <v-checkbox v-model="pullFresh" :label="`Re-pull my membership from ${selectedBody.label}`" color="success"></v-checkbox>
                      </div>
                    </v-expand-transition>
                    <v-expand-transition>
                      <div v-if="numToValidate === invalid">
                        <v-alert type="error" :value="true">
                          We could not verify this number.
                        </v-alert>
                        <span class="subheading" v-if="selectedBody.model === 'aauNumber'">
                          Please make sure your last name, date of birth, and zip code are up to date and try again.
                        </span>
                      </div>
                    </v-expand-transition>
                    <v-btn
                      color="success"
                      @click.stop="selectedBody.click"
                      :disabled="!numToValidate || (numToValidate === model[selectedBody.model] && !pullFresh) || numToValidate === invalid"
                      :loading="validating"
                    >Validate</v-btn>
                  </v-col>
                </v-row>
                <v-expand-transition>
                  <v-row dense v-if="model[selectedBody.model] && numToValidate === model[selectedBody.model] && !selectedBody.request">
                    <v-col class="text-center" cols="12" >
                      <v-btn color="color3" text class="xsmall" @click.stop="clear = true">Remove this number</v-btn>
                      <v-expand-transition>
                        <div v-if="clear">
                          <v-divider></v-divider>
                          Are you sure?
                          <div>
                            <v-btn
                              color="success"
                              class="mr-3"
                              :loading="loading"
                              @click.stop="selectedBody.clear"
                            >Yes</v-btn>
                            <v-btn color="error" @click.stop="clear = false">No</v-btn>
                          </div>
                        </div>
                      </v-expand-transition>
                    </v-col>
                  </v-row>
                </v-expand-transition>
              </v-window-item>
              <v-window-item :value="1">
                <v-row dense v-if="validationResult" class="text-center">
                  <v-col cols="12" class="subheading">
                    {{numToValidate}} is a valid AVP America Number belonging to
                    {{validationResult.firstName}} {{validationResult.lastName}}
                  </v-col>
                  <v-col cols="12" class="pt-4">
                    <v-btn
                      color="color3 color3Text--text"
                      @click.stop="selectedBody.save"
                      :loading="loading"
                    >That's me... Save it</v-btn>
                  </v-col>
                  <v-col cols="12">
                    <v-alert type="warning" :value="validationResult.status === 'expired'">
                      FYI, this AVP America Number is currently expired
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-btn
                    color="color3Text color3--text"
                    small fab
                    @click.stop="window = 0"
                  >
                    <v-icon>fas fa-caret-left</v-icon>
                  </v-btn>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-expand-transition>
    </v-dialog>
  </v-card>
</template>

<script>
import Logos from '@/classes/LogoUrls'
import { mapGetters } from 'vuex'
const MembershipAdd = () => import('@/components/Player/Editor/MembershipAdd')

export default {
  props: ['player', 'model', 'editing'],
  data () {
    return {
      loading: false,
      dialog: false,
      selectedBody: null,
      numToValidate: null,
      window: 0,
      validating: false,
      validationResult: null,
      clear: false,
      invalid: false,
      refreshKey: 0,
      pullFresh: false
    }
  },
  computed: {
    ...mapGetters(['user', 'subdomain']),
    logos () {
      return Logos
    },
    a () {
      return {
        age: this.player.age,
        j: this.player.isJunior
      }
    },
    bodies () {
      return [
        {
          label: 'AAU Number',
          model: 'aauNumber',
          hint: 'We do not have a valid AAU number on file for you',
          logo: Logos.aau,
          validate: true,
          expired: this.player.aauNumber && this.player.aauExpired,
          click: this.validateAAU,
          save: () => { this.saveNumber('aauNumber') },
          clear: () => { this.saveNumber('aauNumber', true) },
          hide: this.player.isAdult && !this.player.aauNumber
        },
        {
          label: 'AVP America Number',
          model: 'avpAmericaNumber',
          hint: 'We do not have a valid AVP America Number number on file for you',
          logo: Logos.avpamerica,
          validate: true,
          click: this.validateAVP,
          save: () => { this.saveNumber('avpAmericaNumber') },
          clear: () => { this.saveNumber('avpAmericaNumber', true) },
          expired: this.player.avpAmericaNumber && this.player.avpaExpired
        },
        {
          label: 'BVCA Number',
          model: 'bvcaNumber',
          hint: 'We do not have a valid BVCA number on file for you',
          logo: Logos.bvca,
          hide: true
        },
        {
          label: 'BVNE Number',
          model: 'bvneNumber',
          hint: 'We do not have a valid BVNE number on file for you',
          logo: Logos.bvne,
          hide: true
        },
        {
          label: 'p1440 Number',
          model: 'p1440Number',
          hint: 'We do not have a valid p1440 number on file for you',
          logo: Logos.p1440,
          validate: true,
          request: true,
          expired: this.player.p1440Expired
        },
        {
          label: 'USAV Number',
          model: 'usavNumber',
          hint: 'We do not have a valid USAV number on file for you',
          logo: Logos.usav
        }
      ].filter(f => !f.hide)
    }
  },
  methods: {
    selectedDto (field, clear) {
      const val = clear ? null : this.numToValidate
      if (this.model[field] === val) return null
      var dto = {
        id: this.player.id
      }
      dto[field] = val
      return dto
    },
    saveNumber (field, clear) {
      if (!(this.dirty || (field && (this.numToValidate || clear)))) return
      const dto = field ? this.selectedDto(field, clear) : this.dto
      if (!dto) return this.onSaved()
      this.loading = true
      this.$VBL.player.patchNumber(dto)
        .then(r => {
          this.onSaved(r.data)
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    validateAVP () {
      const n = this.numToValidate
      if (!n) return
      this.validating = true
      this.$VBL.verifyAVPA(n)
        .then(r => {
          this.validationResult = r.data
          if (r.data.status === 'false') {
            this.invalid = n
            return
          }
          this.$nextTick(() => {
            this.window = 1
          })
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.validating = false })
    },
    validateAAU () {
      if (!this.numToValidate) return
      this.validating = true
      const dto = this.selectedDto('aauNumber', false)
      this.$VBL.player.patchNumber(dto)
        .then(r => {
          this.onSaved(r.data)
        })
        .catch(e => {
          if (e.response.data === 'invalid') {
            this.invalid = dto.aauNumber
          }
          console.log(e.response)
        })
        .finally(() => { this.validating = false })
    },
    onFocus (body) {
      if (!(body && body.validate)) return
      this.selectedBody = body
      this.numToValidate = this.model[body.model]
      this.dialog = true
    },
    onSaved (result) {
      result && this.$emit('number-saved', result)
      this.dialog = false
      this.refreshKey++
    }
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.window = 0
        this.selectedBody = null
        this.numToValidate = null
        this.validationResult = null
        this.clear = false
        this.invalid = false
      }
    }
  },
  components: {
    MembershipAdd
  },
  mounted () {
  }
}
</script>

<style scoped>
>>> .warn .error--text,
>>> .warn.error--text {
  color: #FFC107 !important
}
</style>
