<template>
  <div>
    <v-card class="mb-4">
      <v-card-title class="headline text-center justify-center">
        Basic Profile Information
      </v-card-title>
      <v-divider></v-divider>
      <!-- Profile -->
      <v-card-text>
        <v-row dense>
          <!-- IMAGE -->
          <v-col cols="12" md="3">
              <v-img
                :src="pic"
                max-height="100%"
                aspect-ratio="1"
              >
                <v-fab-transition>
                  <v-btn
                    color="error white--text"
                    class="float-right ma-1"
                    fab small
                    @click.stop="unsavedImage = null"
                    v-if="unsavedImage"
                  >
                    <v-icon>fas fa-times</v-icon>
                  </v-btn>
                </v-fab-transition>
                <v-expand-transition>
                    <div class="d-flex v-card--reveal color3 color3Text--text clickable" v-if="editing && !unsavedImage" style="height: 50%">
                      <image-input v-model="unsavedImage" ref="uploader">
                        <v-container class="fill-height">
                          <v-row dense>
                            <v-col class="text-center" cols="12" >
                              <v-icon color="color3Text" large>fas fa-camera</v-icon>
                              <div>{{player.profilePic ? 'Update' : 'Upload a profile picture'}}</div>
                            </v-col>
                          </v-row>
                        </v-container>
                      </image-input>
                    </div>
                </v-expand-transition>
              </v-img>
          </v-col>
          <!-- BASIC CONTACT -->
          <v-col cols="12" md="9">
            <h1 class="mb-2">{{player.fullname}}</h1>
            <v-card flat outline>
              <person-form
                :player="model"
                :disable="!editing"
                :fields="['name', 'gender', 'fullAddress']"
                :requiredFields="['phone', 'email']"
                :allowInternational="true"
                :noSetValid="true"
              ></person-form>
            </v-card>
          </v-col>
          <!-- ADDITIONAL REGISTRATION INFO -->
          <v-col cols="12">
            <v-card flat outline>
              <person-form
                :player="model"
                :disable="!editing"
                :fields="['shirtSize', 'height', 'dob', 'gradyear', 'club', 'committed', 'highSchool']"
                :requiredFields="[]"
                :noSetValid="true"
                :bvcaRestrict="bvcaRestrict"
              ></person-form>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <player-mapping v-if="view && view.vbl" :id="player.id"></player-mapping>

    <v-card class="my-4">
      <v-card-title class="headline text-center justify-center">
        Introduction
      </v-card-title>
      <v-divider></v-divider>
      <!-- Profile -->
      <v-card-text>
        <v-row dense>
          <!-- ADDITIONAL REGISTRATION INFO -->
          <v-col cols="12">
            <v-card flat outline>
              <v-textarea
                label="Introduction"
                v-model="model.jProps.bio1"
                outlined
                color="color3"
                persistent-hint
                :disabled="!editing"
                hint="Introduce yourself to the college coaches. Tell them why you want to play in college, what you like about volleyball or feel free to put additional results here."
              ></v-textarea>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <sanctioning-bodies
      v-if="player"
      :player="player"
      :editing="editing"
      :model="model"
      @number-saved="onSaved"
    ></sanctioning-bodies>

    <v-card class="mt-4">
      <v-card-title class="headline text-center justify-center">
        Social Media
      </v-card-title>
      <v-divider></v-divider>
      <!-- Profile -->
      <v-card-text>
        <v-row dense>
          <!-- ADDITIONAL REGISTRATION INFO -->
          <v-col cols="12">
            <v-card flat outline>
              <person-form
                :player="social"
                :disable="!editing"
                :fields="['social']"
                :requiredFields="[]"
                :noSetValid="true"
              ></person-form>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-fab-transition><!-- CANCEL -->
      <v-btn
        color="success white--text"
        fab
        :small="smallFab"
        fixed bottom right
        @click.stop="onSaveClick"
        v-if="editing"
        :loading="loading"
        :disabled="!saveable"
        :style="smallFab ? 'margin-right: 45px;' : 'margin-right: 65px;'"
      >
        <v-icon>fas fa-save</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-fab-transition><!-- EDIT/SAVE -->
      <v-btn
        v-if="fab"
        :color="fab.color"
        fab
        :small="smallFab"
        :key="fab.key"
        @click.stop="fab.click"
        :loading="fab.loading"
        :disabled="fab.disabled"
        fixed bottom right
      >
        <v-icon>{{fab.icon}}</v-icon>
      </v-btn>
    </v-fab-transition>
  </div>
</template>

<script>
import PersonForm from '@/components/Forms/PersonForm'
import ImageInput from '@/components/Utils/ImageUploaderNew.vue'
import SanctioningBodies from '@/components/Player/Editor/SanctioningBodiesForm'
const PlayerMapping = () => import('@/components/Player/Editor/PlayerMapping')

export default {
  props: ['player', 'view', 'active'],
  data () {
    return {
      editing: false,
      editable: null,
      unsavedImage: null,
      loading: false
    }
  },
  computed: {
    bvcaRestrict () {
      return this.player && this.player.memberships.includes('b') && !(this.view.vbl || this.view.td)
    },
    model () {
      return this.editing ? this.editable : this.dataOg
    },
    social () {
      return this.model.jProps.social
    },
    smallFab () {
      return this.$vuetify.breakpoint.smAndDown
    },
    fab () {
      return this.editing ? {
        key: 'cancel',
        color: 'error white--text',
        icon: 'fas fa-times',
        click: this.cancel,
        loading: this.loading
      } : {
        key: 'edit',
        color: 'color3 color3Text--text',
        icon: 'fas fa-pencil',
        click: this.edit,
        loading: false,
        disabled: this.loading
      }
    },
    dataOg () {
      return this.player.basicInfoDto
    },
    dirty () {
      if (this.unsavedImage) return true
      for (const prop in this.dto) {
        if (prop !== 'id') return true
      }
      return false
    },
    saveable () {
      return this.dirty
    },
    __test () {
      for (const prop in this.dto) {
        if (prop !== 'id') return true
      }
      return false
    },
    dto () {
      if (!this.editable) return null
      const a = {
        id: this.player.id
      }
      const skip = ['aauNumber', 'avpAmericaNumber', 'p1440Number', 'id', 'jProps']
      if (JSON.stringify(this.editable.jProps) !== this.player.jsonProps) {
        a.jsonProps = JSON.stringify(this.editable.jProps)
      }
      for (const prop in this.dataOg) {
        if (!skip.includes(prop)) {
          if (this.editable[prop] !== this.dataOg[prop]) {
            a[prop] = this.editable[prop]
            if (a[prop] === undefined || a[prop] === '') a[prop] = null
          }
        }
      }
      return a
    },
    pic () {
      return this.unsavedImage ? this.unsavedImage.imageURL : this.player.profilePic
    }
  },
  methods: {
    edit () {
      this.editable = JSON.parse(JSON.stringify(this.dataOg))
      this.editing = true
    },
    cancel () {
      this.editing = false
      this.editable = null
      this.unsavedImage = null
    },
    onSaveClick () {
      console.log(this.dto)
      this.loading = true
      const image = this.unsavedImage ? this.unsavedImage.formData : null
      this.$VBL.player.post(this.dto, image)
        .then(r => {
          this.onSaved(r.data)
          if (image) this.player.imgI++
        })
        .catch(e => { console.log(e.response) })
        .finally(() => { this.loading = false })
    },
    onSaved (result) {
      result && this.player.update(result)
      this.cancel()
    },
    onNumberSaved (result) {
      if (result) {
        console.log(result)
        this.player.update(result)
        Object.assign(this.editable, result)
      }
    },
    setHsName (v) {
      if (v && this.player.highSchool) {
        this.model.jProps.programs.beach.hs.name = this.player.highSchool
      }
    },
    setHsNameIndoor (v) {
      if (v && this.player.highSchool) {
        this.model.jProps.programs.indoor.hs.name = this.player.highSchool
      }
    }
  },
  watch: {
    active: function (val) {
      if (!val) {
        this.cancel()
      }
    }
  },
  components: {
    SanctioningBodies,
    PersonForm,
    ImageInput,
    PlayerMapping
  }
}
</script>

<style scoped>
>>> .v-input--radio-group__input {
  align-items: flex-start;
}
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
.v-card--overlay {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
</style>
